import './App.css';
import React, {useState, useEffect} from 'react';

export default function Loader() {

    const [width, setWidth] = useState(1);

    function getRandom(min, max) {
        return Math.random() * (max - min) + min;
      }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setWidth(width + getRandom(0,1.5))
        }, getRandom(250,500));
        return () => {
            clearInterval(intervalId)
        }
    })

    return <div className='loader-container'>
        <p className='loader-h' style={{marginBottom:'10px'}}>Please allow the database a few seconds to fully load.</p>
        <p className='about'>This may take up to a minute depending on the speed of your connection. Loading occurs only once, after which point you may choose other dates freely.</p>
        <div className='loader' style={width < 100 ? {width:`${width}%`} : {width:'100%'}}>
        </div>
        {(width > 5 && width < 15) &&
            <p className='loader-text'>Gathering financial news...</p>
        }
        {(width > 15 && width < 30) &&
        <p className='loader-text'>Reading articles...</p>
        }
        {(width > 30 && width < 45) &&
            <p className='loader-text'>Indexing data...</p>
        }
        {(width > 45 && width < 60) &&
        <p className='loader-text'>Studying price movements...</p>
        }
        {(width > 60 && width < 75) &&
            <p className='loader-text'>Aggregating volume...</p>
        }
        {(width > 75 && width < 90) &&
        <p className='loader-text'>Generating charts...</p>
        }
        {width > 90 &&
        <p className='loader-text-2'>Finalizing information...</p>
        }
        </div>
}