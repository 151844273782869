import './App.css';
import React from 'react';

export default function Articles() {

    return (
        <>
        <div className='main-container'>
        <div className='about-container'>
        <p className='about'>
        I enjoy reading and writing about books and have published with a variety of publications. 
        A handful of my reviews, essays, and interviews are listed here. Please feel free
        to reach out directly if you are unable to access any of the below.</p>
        </div>
        <div className='article-container'>
        <div className='review2'>
            {reviews.map(a => {
                return <p key={a.id} className='review-time'>{a['date']}</p>
            })}
        </div>
        <div className='review3'>
        {reviews.map(a => {
            return <a href={a.src} target='blank' className='article-link'>
                <div className='review'>
                <p className='article-headline'>{a['title']}</p>
                <p className='review-source'>{a['publication']}</p>
            </div>
            </a>
        })}
        </div>
        </div>
        </div>

        </>
    )

}

const reviews = [
    {   id: 0,
        src: "https://www.chicagoreview.org/issues/6401-02-03/",
        details: "Chicago Review",
        title: "Language's Resolve: the Fiction of Garielle Lutz",
        publication: "Chicago Review",
        date: "June 2021"  },
    {   id: 1,
        src: "https://www.tupeloquarterly.com/to-connect-figures-of-isolation-and-affliction-an-interview-with-andrew-hungate-conducted-by-zach-savich/",
        details: "Tupelo Quarterly",
        title: "To Connect Figures of Isolation and Affliction",
        publication: "Tupelo Quarterly",
        date: "March 2020"  },
    {   id: 2,
        src: "https://www.3ammagazine.com/3am/98076-2/",
        details: `"3:AM Magazine - Zadie Smith"`,
        title:  "Grand Union by Zadie Smith: Review",
        publication: "3:AM Magazine",
        date: "October 2019"},
    {   id: 3,
        src: "https://www.3ammagazine.com/3am/essays-one/",
        details: "3:AM Magazine - Lydia Davis",
        title:  "Essays One by Lydia Davis: Review",
        publication: "3:AM Magazine",
        date: "September 2019" },
    {   id: 4,
        src: "http://www.literarymatters.org/11-2-new-styles-for-criticism-an-interview-with-jeff-dolven/",
        details: "Literary Matters - Jeff Dolven",
        title:  "An Interview with Jeff Dolven",
        publication: "Literary Matters",
        date: "February 2019"   },
    {   id: 5,
        src: "https://www.zyzzyva.org/2018/07/17/all-the-news-thats-fit-to-be-normalized-hilary-plums-strawberry-fields/",
        details: "ZYZZYVA - Hilary Plum",
        title:  "All the News That's Fit to be Normalized",
        publication: "ZYZZYVA",
        date: "July 2018"   },
    {   id: 6,
        src: "https://www.wordswithoutborders.org/book-review/the-april-3rd-incident-stories-showcases-yu-huas-daring-and-unconventional",
        details: "Words Without Borders - Yu Hua",
        title:  "Yu Hua's The April 3rd Incident",
        publication: "Words Without Borders",
        date: "May 2018"   },
    {   id: 7,
        src: "https://www.zyzzyva.org/2018/04/06/this-shifting-web-stream-system-and-border-districts-by-gerald-murnane/",
        details: "ZYZZYVA - Gerald Murnane",
        title:  "This Shifting Web: Border Districts & Stream System",
        publication: "ZYZZYVA",
        date: "April 2018"   },
    {   id: 8,
        src: "https://www.wordswithoutborders.org/book-review/yoko-tawada-dystopian-novel-the-emissary-is-a-bitingly-smart-satire-japan",
        details: "Words Without Borders - Yoko Tawada",
        title:  "A Bitingly Smart Satire of Japan",
        publication: "Words Without Borders",
        date: "February 2018"   },
    {   id: 9,
        src: "http://vol1brooklyn.com/2018/01/11/memory-regrets-and-memorials-a-review-of-denis-johnsons-the-largesse-of-the-sea-maiden/",
        details: "Vol. 1 Brooklyn - Denis Johnson",
        title:  "Memorials: Denis Johnson in Review",
        publication: "Vol. 1 Brooklyn",
        date: "January 2018"   },
    {   id: 10,
        src: "http://vol1brooklyn.com/2017/12/04/fictional-absences-and-incomplete-fictions-a-review-of-dandelions-by-yasunari-kawabata/",
        details: "Vol. 1 Brooklyn - Kawabata",
        title:  "Fictional Absences and Incomplete Fictions",
        publication: "Vol. 1 Brooklyn",
        date: "December 2017"   },
    {   id: 11,
        src: "https://academic.oup.com/litimag/advance-article-abstract/doi/10.1093/litimag/imx071/4638896?redirectedFrom=fulltext",
        details: "Literary Imagination",
        title:  "The Forms of Psychoanalysis in Watt",
        publication: "Oxford University Press",
        date: "November 2017"   },
    {   id: 12,
        src: "http://www.literarymatters.org/10-1-love-of-metaphor-a-review-of-megan-levads-what-have-i-to-say-to-you/",
        details: "Literary Matters - Megan Levad",
        title:  "Review: What Have I To Say To You",
        publication: "Literary Matters",
        date: "October 2017"   }
  ]