import './App.css';
import React from 'react';

export default function ProjectOverview() {

    return (
        <>
        <div className='main-container'>
        <div className='about-container'>
        <h2>A year of financial news</h2>
        <p className='about'>
        
        I began this project as an investigation into the rise of retail investing that occurred 
        with the onset of COVID-19. Working under the assumption that more individuals entering the 
        market at this time must have strengthened causal relationships between financial news and market activity, 
        I began building a database to analyze these relationships and understand the patterns, if any, that were present in the data. 
        <a className='emphasis'>The tool on this website visualizes a database of financial news and related market activity
         for one year beginning March 1, 2020 and ending February 28, 2021.</a> 
        <br /><br />
        <h2>Visualization</h2>
        This tool combines intraday pricing data at sixty-second intervals for the NYSE and Nasdaq with a dataset that I built from
        scraping 10,000+ articles from sources including Reuters, CNBC, PR News Wire, Bloomberg, Barron's, the Wall Street Journal, and more.
        <br /><br />
        The final version is filtered to include only a portion of relevant news and trading days (see notes below), but it is intended to 
        allow the user to explore how a given day's news cycle may correlate with price and volume 
        fluctuation in the relevant securities. The tool attempts to capture the earliest instantiation of
        each piece of news, and includes both a single-day and ten-day view to allow for context, especially in cases 
        where the market reacted to information on the day before it was published.
        <br /><br />
        <h2>Notes</h2>
        </p>
        <ul className='indent'>
        <li>The database only covers market activity (9:30 to 4), as before/after-market data is less consistent and has less retail investor 
        activity. Only companies on the NYSE or Nasdaq exchanges are included in the dataset.</li>
        <br /><br />
        <li>As a general rule, stocks are shown for a given day only if they demonstrated increased trading volume by 50% or more either on that 
        date or the previous market day. This is a way to naturally limit the information shown, as well as to focus on the companies and 
        related news that most captured investor interest.</li>
        <br /><br />
        <li>Visualizations, charts and graphics are generated with raw data from eoddata.com and translated with custom SVG functions. 
        All code on this site is my own.</li>
        </ul>
        <br /><br />
        <br /><br />
        <br /><br />
        </div>
        </div>
        </>
    )

}