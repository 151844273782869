import Navbar from './Navbar.js';
import {
  Switch,
  Route
} from "react-router-dom";
import Articles from './Articles.js';
import React, { Suspense, lazy } from 'react';
import About from './About.js';
import ProjectOverview from './ProjectOverview.js';
import Loader from './Loader.js';
import Financial from './Financial.js';
import Behabitive from './Behabitive.js';

const ScrapingApp = lazy(() => import('./ScrapingApp.js'));


function App() {
  return (
    <>
    <Navbar></Navbar>
    <Suspense fallback={<Loader />}>
    <Switch>
    <Route exact path="/financial-scraping-overview">
      <ProjectOverview />
    </Route>
    <Route exact path="/financial-scraping-database">
      <Financial />
    </Route>
    <Route path="/financial-scraping-database/:month/:day">
      <ScrapingApp />
    </Route>
      <Route path="/articles">
        <Articles />
      </Route>
      <Route exact path="/about">
        <About />
      </Route>
      <Route exact path="/behabitive">
        <Behabitive />
      </Route>
      <Route exact path="/">
        <About />
      </Route>
    </Switch>
    </Suspense>
    </>
  );
}

export default App;
