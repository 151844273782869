import './App.css';
import React from 'react';

export default function Behabitive() {

    return (
        <>
        <div className='main-container'>
        <div className='about-container'>
        <h2>An experiment in writing</h2>
        <p className='about'>
        
        Behabitive is a work-in-progress web application with the broad aim of expanding the ways in which 
        people write, learn to write, and think about their own writing. 
        <br /><br />
        If you are interested in testing out the product at its alpha stage, please reach out to me directly.
        I am currently developing Behabitive as a solo project, but am always open to collaborators and 
        enthusiasts who are interested in encouraging broader and better writing tools and pedagogy.
        <br /><br />
        <a className='emphasis' href='https://www.behabitive.com' target='blank'>Behabitive</a> 
        </p>
        </div>
        </div>
        </>
    )

}