import './App.css';
import { Link } from "react-router-dom";
import React, { useState } from 'react';
const stocks = require('./first-stocks-by-date.json');

export default function Financial() {
    const [month, setMonth] = useState('Default')
    const [day, setDay] = useState('')

    const months = [' ', ' ', ' ', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 
'Oct', 'Nov', 'Dec', 'Jan', 'Feb', ' ', ' ', ' '];
    const months2 = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 
'Oct', 'Nov', 'Dec'];

    const filename = `${months2.indexOf(month) < 2 ? '2021' : '2020'}-${months2.indexOf(month) + 1}-${day}`

    const monthFiltered = (month !== '') ? monthDays.filter(m => m['month'] === month)[0]['days'] : []

    const stock = (month === 'Default' || day === '') ? 'A' : stocks.find(s => s['Date'] === filename)['Stock'];

    function handleMonth(e) {
        setMonth(e);
    }

    function handleDay(d) {
        setDay(d);
    }

    return (
        <>
        <div className='main-container'>
                <h2 className='hidden' style={{marginBottom:'45px', textAlign:'center'}}>Pick a date:</h2>
                <h2 className='phone-h2'style={{textAlign:'center'}}>This tool is currently only available for viewing on desktop or tablet-sized screens.</h2>
            <div className='calendar-container1'> 
                <div className={`calendar-container-month-main ${month === 'Default' ? 'light' : 'dark'}`}>
                <div className='calendar-container2-month'>
                    <div className='calendar-container-month inactive phone-hidden'>
                        <p style={{ color:'white', fontSize:'0.75rem', paddingTop:'18px'}}>2020</p>
                    </div>
                    {months.map(m => {
                    return <div className={`calendar-container-month ${m === ' ' && 'inactive'} ${month !== 'Default' && (month === m ? 'calendar-active' : 'calendar-inactive')}`} 
                                key={m} onClick={() => {
                                    handleDay('')
                                    handleMonth(m)
                                    }}>
                                <a href='' style={m === 'Jan' || m === 'Feb' ? {color:'#A8E0F9'} : {color:'white'}} onClick={(event) => {
                                  event.preventDefault();
                                }}>{m}</a>
                            </div>
                        })}
                    <div className='calendar-container-month inactive phone-hidden'>
                        <p style={{ fontSize:'0.75rem', color:'#A8E0F9', marginTop:'-7.5px'}}>2021</p>
                    </div>
                </div>
                </div>
                <div className={`calendar-container-day-main ${month === 'Default' || day !== '' ? 'dark' : 'light'}`} style={{marginLeft:'90px', paddingLeft:'0px'}}>
                <div className='calendar-container2-day'>
                    {weekDiv.map((w, index) => <div key={index} className='non-market-day calendar-container-day'>{w}</div>)}
                    {monthFiltered.map((d, index) => <a href='' className={d['open'] ? 'market-day' : 'non-market-day'} onClick={(event) => {
                        event.preventDefault();
                        handleDay(d['day'])
                    }}>
                        <div className={`calendar-container-day ${day !== '' && (day === d['day'] ? 'calendar-active' : 'calendar-inactive')}`} key={index}>
                            {d['day']}
                        </div></a>
                        )}
                </div>
                </div>
            </div>
            <Link to={`/financial-scraping-database/${month}/${day}/${stock}`}>
            <div className={`button ${day !== '' && 'button-active'}`}><p style={{ paddingTop:'18px' }}>View data</p></div>
            </Link>
        </div>
        </>
    )
}

const weekDiv = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

const monthDays = [
    { month : 'Mar',
      days : [
          { day : '1', open : false }, { day : '2', open : true }, { day : '3', open : true },
          { day : '4', open : true }, { day : '5', open : true }, { day : '6', open : true },
          { day : '7', open : false }, { day : '8', open : false }, { day : '9', open : true },
          { day : '10', open : true }, { day : '11', open : true }, { day : '12', open : false },
          { day : '13', open : true }, { day : '14', open : false }, { day : '15', open : false },
          { day : '16', open : true }, { day : '17', open : true }, { day : '18', open : false },
          { day : '19', open : true }, { day : '20', open : true }, { day : '21', open : false },
          { day : '22', open : false }, { day : '23', open : true }, { day : '24', open : true },
          { day : '25', open : true }, { day : '26', open : true }, { day : '27', open : true },
          { day : '28', open : false }, { day : '29', open : false }, { day : '30', open : true },
          { day : '31', open : true }, { day : ' ', open : false }, { day : ' ', open : false },
          { day : ' ', open : false }, { day : ' ', open : false }
      ] },
      { month : 'Apr',
      days : [
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : '1', open : true }, { day : '2', open : true }, { day : '3', open : true },
        { day : '4', open : false }, { day : '5', open : false }, { day : '6', open : true },
        { day : '7', open : true }, { day : '8', open : true }, { day : '9', open : true },
        { day : '10', open : false }, { day : '11', open : false }, { day : '12', open : false },
        { day : '13', open : true }, { day : '14', open : true }, { day : '15', open : true },
        { day : '16', open : true }, { day : '17', open : true }, { day : '18', open : false },
        { day : '19', open : false }, { day : '20', open : true }, { day : '21', open : true },
        { day : '22', open : true }, { day : '23', open : true }, { day : '24', open : true },
        { day : '25', open : false }, { day : '26', open : false }, { day : '27', open : true },
        { day : '28', open : true }, { day : '29', open : true }, { day : '30', open : true },
        { day : ' ', open : false }, { day : ' ', open : false }
      ] },
      { month : 'May',
      days : [
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : '1', open : true }, { day : '2', open : false }, { day : '3', open : false },
        { day : '4', open : true }, { day : '5', open : true }, { day : '6', open : true },
        { day : '7', open : true }, { day : '8', open : true }, { day : '9', open : false },
        { day : '10', open : false }, { day : '11', open : true }, { day : '12', open : true },
        { day : '13', open : true }, { day : '14', open : true }, { day : '15', open : true },
        { day : '16', open : false }, { day : '17', open : false }, { day : '18', open : true },
        { day : '19', open : true }, { day : '20', open : true }, { day : '21', open : true },
        { day : '22', open : true }, { day : '23', open : false }, { day : '24', open : false },
        { day : '25', open : false }, { day : '26', open : true }, { day : '27', open : true },
        { day : '28', open : true }, { day : '29', open : true }, { day : '30', open : false },
        { day : '31', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }
      ] },
      { month : 'Jun',
      days : [
        { day : ' ', open : false }, 
        { day : '1', open : true }, { day : '2', open : true }, { day : '3', open : true },
        { day : '4', open : true }, { day : '5', open : true }, { day : '6', open : false },
        { day : '7', open : false }, { day : '8', open : true }, { day : '9', open : true },
        { day : '10', open : true }, { day : '11', open : true }, { day : '12', open : true },
        { day : '13', open : false }, { day : '14', open : false }, { day : '15', open : true },
        { day : '16', open : true }, { day : '17', open : true }, { day : '18', open : true },
        { day : '19', open : true }, { day : '20', open : false }, { day : '21', open : false },
        { day : '22', open : true }, { day : '23', open : true }, { day : '24', open : true },
        { day : '25', open : true }, { day : '26', open : true }, { day : '27', open : false },
        { day : '28', open : false }, { day : '29', open : true }, { day : '30', open : true },
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }
      ] },
      { month : 'Jul',
      days : [
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : '1', open : true }, { day : '2', open : true }, { day : '3', open : false },
        { day : '4', open : false }, { day : '5', open : false }, { day : '6', open : true },
        { day : '7', open : true }, { day : '8', open : true }, { day : '9', open : true },
        { day : '10', open : true }, { day : '11', open : false }, { day : '12', open : false },
        { day : '13', open : true }, { day : '14', open : true }, { day : '15', open : false },
        { day : '16', open : true }, { day : '17', open : true }, { day : '18', open : false },
        { day : '19', open : false }, { day : '20', open : true }, { day : '21', open : true },
        { day : '22', open : true }, { day : '23', open : true }, { day : '24', open : true },
        { day : '25', open : false }, { day : '26', open : false }, { day : '27', open : true },
        { day : '28', open : true }, { day : '29', open : true }, { day : '30', open : true },
        { day : '31', open : true }, { day : ' ', open : false }
      ] },
      { month : 'Aug',
      days : [
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : '1', open : false }, { day : '2', open : false }, { day : '3', open : true },
        { day : '4', open : true }, { day : '5', open : true }, { day : '6', open : true },
        { day : '7', open : true }, { day : '8', open : false }, { day : '9', open : false },
        { day : '10', open : true }, { day : '11', open : true }, { day : '12', open : true },
        { day : '13', open : true }, { day : '14', open : true }, { day : '15', open : false },
        { day : '16', open : false }, { day : '17', open : true }, { day : '18', open : true },
        { day : '19', open : true }, { day : '20', open : true }, { day : '21', open : true },
        { day : '22', open : false }, { day : '23', open : false }, { day : '24', open : true },
        { day : '25', open : true }, { day : '26', open : true }, { day : '27', open : true },
        { day : '28', open : true }, { day : '29', open : false }, { day : '30', open : false },
        { day : '31', open : true }, { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }
      ] },
      { month : 'Sep',
      days : [
        { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : '1', open : true }, { day : '2', open : true }, { day : '3', open : true },
        { day : '4', open : true }, { day : '5', open : false }, { day : '6', open : false },
        { day : '7', open : false }, { day : '8', open : true }, { day : '9', open : true },
        { day : '10', open : true }, { day : '11', open : false }, { day : '12', open : false },
        { day : '13', open : false }, { day : '14', open : true }, { day : '15', open : true },
        { day : '16', open : true }, { day : '17', open : true }, { day : '18', open : true },
        { day : '19', open : false }, { day : '20', open : false }, { day : '21', open : true },
        { day : '22', open : true }, { day : '23', open : true }, { day : '24', open : true },
        { day : '25', open : true }, { day : '26', open : false }, { day : '27', open : false },
        { day : '28', open : true }, { day : '29', open : true }, { day : '30', open : true },
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }
      ] },
      { month : 'Oct',
      days : [
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : ' ', open : false }, 
        { day : '1', open : true }, { day : '2', open : true }, { day : '3', open : false },
        { day : '4', open : false }, { day : '5', open : true }, { day : '6', open : true },
        { day : '7', open : true }, { day : '8', open : true }, { day : '9', open : true },
        { day : '10', open : false }, { day : '11', open : false }, { day : '12', open : true },
        { day : '13', open : true }, { day : '14', open : true }, { day : '15', open : true },
        { day : '16', open : true }, { day : '17', open : false }, { day : '18', open : false },
        { day : '19', open : true }, { day : '20', open : true }, { day : '21', open : true },
        { day : '22', open : true }, { day : '23', open : true }, { day : '24', open : false },
        { day : '25', open : false }, { day : '26', open : true }, { day : '27', open : true },
        { day : '28', open : true }, { day : '29', open : true }, { day : '30', open : true },
        { day : '31', open : false }
      ] },
      { month : 'Nov',
      days : [  
        { day : '1', open : false }, { day : '2', open : true }, { day : '3', open : true },
        { day : '4', open : true }, { day : '5', open : true }, { day : '6', open : true },
        { day : '7', open : false }, { day : '8', open : false }, { day : '9', open : true },
        { day : '10', open : true }, { day : '11', open : true }, { day : '12', open : true },
        { day : '13', open : true }, { day : '14', open : false }, { day : '15', open : false },
        { day : '16', open : true }, { day : '17', open : true }, { day : '18', open : true },
        { day : '19', open : true }, { day : '20', open : true }, { day : '21', open : false },
        { day : '22', open : false }, { day : '23', open : true }, { day : '24', open : true },
        { day : '25', open : false }, { day : '26', open : false }, { day : '27', open : false },
        { day : '28', open : false }, { day : '29', open : false }, { day : '30', open : true },
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }
      ] },
      { month : 'Dec',
      days : [
        { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : '1', open : true }, { day : '2', open : true }, { day : '3', open : true },
        { day : '4', open : true }, { day : '5', open : false }, { day : '6', open : false },
        { day : '7', open : true }, { day : '8', open : true }, { day : '9', open : true },
        { day : '10', open : true }, { day : '11', open : true }, { day : '12', open : false },
        { day : '13', open : false }, { day : '14', open : false }, { day : '15', open : true },
        { day : '16', open : true }, { day : '17', open : true }, { day : '18', open : true },
        { day : '19', open : false }, { day : '20', open : false }, { day : '21', open : true },
        { day : '22', open : true }, { day : '23', open : true }, { day : '24', open : false },
        { day : '25', open : false }, { day : '26', open : false }, { day : '27', open : false },
        { day : '28', open : true }, { day : '29', open : true }, { day : '30', open : true },
        { day : '31', open : false }, { day : ' ', open : false }, { day : ' ', open : false }
      ] },
      { month : 'Jan',
      days : [
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : '1', open : false }, { day : '2', open : false }, { day : '3', open : false },
        { day : '4', open : true }, { day : '5', open : true }, { day : '6', open : true },
        { day : '7', open : true }, { day : '8', open : true }, { day : '9', open : false },
        { day : '10', open : false }, { day : '11', open : true }, { day : '12', open : true },
        { day : '13', open : true }, { day : '14', open : true }, { day : '15', open : true },
        { day : '16', open : false }, { day : '17', open : false }, { day : '18', open : false },
        { day : '19', open : true }, { day : '20', open : true }, { day : '21', open : false },
        { day : '22', open : true }, { day : '23', open : false }, { day : '24', open : false },
        { day : '25', open : true }, { day : '26', open : true }, { day : '27', open : true },
        { day : '28', open : true }, { day : '29', open : true }, { day : '30', open : false },
        { day : '31', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }
      ] },
      { month : 'Feb',
      days : [
        { day : ' ', open : false }, 
        { day : '1', open : true }, { day : '2', open : true }, { day : '3', open : true },
        { day : '4', open : true }, { day : '5', open : true }, { day : '6', open : false },
        { day : '7', open : false }, { day : '8', open : true }, { day : '9', open : true },
        { day : '10', open : true }, { day : '11', open : true }, { day : '12', open : true },
        { day : '13', open : false }, { day : '14', open : false }, { day : '15', open : false },
        { day : '16', open : true }, { day : '17', open : true }, { day : '18', open : true },
        { day : '19', open : true }, { day : '20', open : false }, { day : '21', open : false },
        { day : '22', open : true }, { day : '23', open : true }, { day : '24', open : true },
        { day : '25', open : true }, { day : '26', open : true }, { day : '27', open : false },
        { day : '28', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, 
        { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }, { day : ' ', open : false }
      ] },
      { month : 'Default',
      days : [
          { day : '1', open : false }, { day : '2', open : false }, { day : '3', open : false },
          { day : '4', open : false }, { day : '5', open : false }, { day : '6', open : false },
          { day : '7', open : false }, { day : '8', open : false }, { day : '9', open : false },
          { day : '10', open : false }, { day : '11', open : false }, { day : '12', open : false },
          { day : '13', open : false }, { day : '14', open : false }, { day : '15', open : false },
          { day : '16', open : false }, { day : '17', open : false }, { day : '18', open : false },
          { day : '19', open : false }, { day : '20', open : false }, { day : '21', open : false },
          { day : '22', open : false }, { day : '23', open : false }, { day : '24', open : false },
          { day : '25', open : false }, { day : '26', open : false }, { day : '27', open : false },
          { day : '28', open : false }, { day : '29', open : false }, { day : '30', open : false },
          { day : '31', open : false },{ day : ' ', open : false },{ day : ' ', open : false },{ day : ' ', open : false },{ day : ' ', open : false }
      ] }

]