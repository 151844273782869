import './App.css';
import React from 'react';

export default function About() {

    return (
        <>
        <div className='main-container'>
        <div className='about-container'>
        <p className='about'>
        <br /><br />

        This website is maintained by Andrew Hungate as a record of current and past projects, interests, and experience. 
        <br /><br />
        Feel free to reach out about writing, finance, product development, or just to chat.
        <br /><br />
        <a href='https://www.linkedin.com/in/andrew-hungate/' target='blank' className='emphasis' style={{textDecoration:'underline'}}>LinkedIn</a>
        </p>
        </div>
        </div>
        </>
    )

}